// Imports
          import InputText from "../../../../components/inputs/InputText";
import InputPhoneNumber from "../../../../components/inputs/inputPhoneNumber/InputPhoneNumber";
import InputTextArea from "../../../../components/inputs/InputTextArea";
import { showModalOK  } from '../../../../components/modal'
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { api } from "../../../../service/mftc-api";


function Component108Page() {
//useNavigate const navigate = useNavigate()


const [content,setContent]= useState( {"Phonenumber":"+33661636717"} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const action_503 =   async ({Phonenumber,setPhonenumber})=> {
        try { 
        if(Phonenumber)
window.location="tel:" + Phonenumber;
}
        catch (error) {
        console.error(error);
        }
      };  

const setPhonenumberbloc1_2_0 =(value)=> setInternal("Phonenumber",value);

const action_event_503_bloc1_2_0 =  async(event)=>{

                            
                           var Phonenumber  = "+33661636717" ;
              
                          await action_503( { Phonenumber,event, setPhonenumber:setPhonenumberbloc1_2_0  }) 
              
                          };  

const action_504 =   async ({Phonenumber,setPhonenumber})=> {
        try { 
         if(Phonenumber)
 window.location="https://wa.me/" + Phonenumber.split(".").join("").replace("+","");
     
}
        catch (error) {
        console.error(error);
        }
      };  

const setPhonenumberbloc1_2_1 =(value)=> setInternal("Phonenumber",value);

const action_event_504_bloc1_2_1 =  async(event)=>{

                            
                           var Phonenumber  = "+33661636717" ;
              
                          await action_504( { Phonenumber,event, setPhonenumber:setPhonenumberbloc1_2_1  }) 
              
                          };  

const action_437 =   async ({MailAddresse,Message,setMailAddresse,setMessage})=> {
        try { 
        
      var result =  await api("/ploy1_envoi_formulaire_par_mail_437_0", {body:{MailAddresse,Message},log:true });
       console.log("Result Back", result)  ;
      

window.alert("Message envoyé avec succès!" )
showModalOK();
setMessage({});
}
        catch (error) {
        console.error(error);
        }
      };  

const setMailAddressebloc6_2_4_0 =(value)=> setInternal("MailAddresse",value);

const setMessagebloc6_2_4_0 =(value)=> setInternal("MessageContact",value);

const action_event_437_bloc6_2_4_0 =  async(event)=>{

                            
                           var MailAddresse  = "taxibourg01@outlook.fr" ;
var Message = content["MessageContact"];
              
                          await action_437( { MailAddresse,Message,event, setMailAddresse:setMailAddressebloc6_2_4_0 , setMessage:setMessagebloc6_2_4_0  }) 
              
                          };  

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "flex flex-row items-center bg-zinc-700 safearea w-screen bg-opacity-50 justify-between bg-secondary_color"}     > <img  id='bloc0_0' src="/profiles/ploy1/user.png" className={ "rounded-xl m-6 h-16 w-16   object-cover"}     alt="undefined" />
          <div id='bloc0_1'  className={ "p-2 text-white font-bold text-xl"}     >{`SAINT-DENIS LES BOURG`} </div>
          <FontAwesomeIcon  id='bloc0_2'  icon={ fa.faCab}      className={ "text-white h-16 w-16 m-6"}  /></div><div id='bloc1'  className={ "w-screen h-screen flex flex-col overflow-hidden relative safearea"}     > <img  id='bloc1_0' src="https://fs.appisyou.com/apps/taxi01/Fondcar1717067804589.png" className={ "w-screen h-screen absolute top-0 left-0   object-cover"}     alt="undefined" />
          <div id='bloc1_1'  className={ "text-white text-2xl grow z-20 flex flex-col justify-start p-4 items-end"}     > <div id='bloc1_1_0'  className={ "text-white z-20 flex flex-col bg-black p-2 bg-opacity-50 justify-end items-end text-sm"}     > <div id='bloc1_1_0_0'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_0_0'  icon={ fa.faHospitalSymbol}      className={ "p-1"}  />
                <div id='bloc1_1_0_0_1'  className={ "font-bold p-1"}     >{`TAXI conventionné CPAM`} </div></div>
              <div id='bloc1_1_0_1'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_1_0'  icon={ fa.faPlaneDeparture}      className={ "p-1"}  />
                <div id='bloc1_1_0_1_1'  className={ "font-bold p-1"}     >{`Tout aéroport`} </div></div>
              <div id='bloc1_1_0_2'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_2_0'  icon={ fa.faTrainSubway}      className={ "p-1"}  />
                <div id='bloc1_1_0_2_1'  className={ "font-bold p-1"}     >{`Toute gare`} </div></div>
              <div id='bloc1_1_0_3'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_3_0'  icon={ fa.faRoad}      className={ "p-1"}  />
                <div id='bloc1_1_0_3_1'  className={ "font-bold p-1"}     >{`Grands trajets`} </div></div>
              <div id='bloc1_1_0_4'  className={ "flex centre flex-row-reverse  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_1_0_4_0'  icon={ fa.faClockRotateLeft}      className={ "p-1"}  />
                <div id='bloc1_1_0_4_1'  className={ "font-bold p-1"}     >{`24/24 7/7`} </div></div></div></div>
          <div id='bloc1_2'  className={ "p-2 w-full left-0 flex centre bottom-4 fixed z-30 items-center justify-center  items-center justify-center"}     > <div id='bloc1_2_0'  className={ "text-white font-semibold py-2 rounded-2xl appbutton bg-secondary_color flex flex-row centre border-white border-4 text-lg px-4  items-center justify-center"}    onClick = { action_event_503_bloc1_2_0} > <FontAwesomeIcon  id='bloc1_2_0_0'  icon={ fa.faPhone}      className={ "p-2"}  />
              <div id='bloc1_2_0_1'  className={ "p-2"}     >{ content["Phonenumber"]} </div></div>
            <div id='bloc1_2_1'  className={ "bg-green-500 border-white border-4 rounded-full p-2 ml-4"}    onClick = { action_event_504_bloc1_2_1} > <FontAwesomeIcon  id='bloc1_2_1_0'  icon={ fa.faPhone}      className={ "p-2 text-white text-lg"}  /></div></div>
          <div id='bloc1_3'  className={ "absolute  width=100vw z-30 top-0 left-0 safearea flex flex-row justify-end items-end"}   style = { {"width":"100vw"}}   > </div></div><div id='bloc2'  className={ "bg-white p-2"}     > <div id='bloc2_0'  className={ "w-full p-6 flex md:flex-row justify-center text-primary_color items-center flex-col-reverse"}     > <div id='bloc2_0_0'  className={ "font-bold my-2 px-4 grow text-center text-2xl"}     >{`TAXI CONVENTIONNÉ CPAM`} </div>
            <div id='bloc2_0_1'  className={ "flex flex-col md:w-1/3 centre  items-center justify-center"}     > <FontAwesomeIcon  id='bloc2_0_1_0'  icon={ fa.faHSquare}      className={ "my-2 text-lg text-center w-20 h-20"}  /></div></div>
          <div id='bloc2_1'  className={ "w-full text-lg p-2 px-6 whitespace-pre-line text-center"}     >{`Optez pour le taxi conventionné pour vos déplacements médicaux.
  Service pris en charge par la Sécurité Sociale, sans avance de frais, et assuré par des chauffeurs professionnels et bienveillants.
  Idéal pour consultations, hospitalisations ou traitements réguliers. 
  Voyagez confortablement et en toute sérénité. Réservez dès maintenant et simplifiez vos trajets médicaux. 
  
  Contactez-nous pour plus d'informations.`} </div></div><div id='bloc3'  className={ "bg-primary_color text-white p-2"}     > <div id='bloc3_0'  className={ "w-full p-6 flex flex-col justify-center md:flex-row items-center"}     > <div id='bloc3_0_0'  className={ "flex flex-col md:w-1/3 centre  items-center justify-center"}     > <FontAwesomeIcon  id='bloc3_0_0_0'  icon={ fa.faMapMarkerAlt}      className={ "my-2 text-lg text-center h-20 w-20"}  /></div>
            <div id='bloc3_0_1'  className={ "font-bold my-2 px-4 grow text-center text-2xl"}     >{`TRAJET DANS TOUTE LA FRANCE`} </div>
            <div id='bloc3_0_2'  className={ "flex flex-col md:w-1/3 centre  items-center justify-center"}     > </div></div>
          <div id='bloc3_1'  className={ "w-full p-2 text-lg px-6 whitespace-pre-line text-center"}     >{`Saviez-vous que notre service de taxi dessert tous les aéroports ainsi que les gares ferroviaires du territoire ?
  
  Ne vous limitez pas à des solutions locales imposées. Choisissez un service personnalisé et de qualité, parfaitement adapté à vos besoins.
  Avec notre flotte de taxis, nous couvrons toute la région lyonnaise et au-delà.
  Nous sommes également disponibles pour vos trajets à travers toute la France et à l'international, incluant des destinations en Suisse, en Italie, en Belgique, et en Allemagne.
  Bénéficiez d'un confort optimal et d'une flexibilité inégalée pour tous vos déplacements, qu'ils soient professionnels ou personnels.
  
  Contactez-nous dès maintenant pour plus d'informations et pour réserver votre prochain trajet en toute sérénité.`} </div></div><div id='bloc4'  className={ "bg-white p-2"}     > <div id='bloc4_0'  className={ "w-full p-6 flex md:flex-row justify-center text-primary_color items-center flex-col-reverse"}     > <div id='bloc4_0_0'  className={ "font-bold my-2 px-4 grow text-center text-2xl"}     >{`DISPONIBILITÉ 24/24 7/7`} </div>
            <div id='bloc4_0_1'  className={ "flex flex-col md:w-1/3 centre  items-center justify-center"}     > <FontAwesomeIcon  id='bloc4_0_1_0'  icon={ fa.faClock}      className={ "my-2 text-lg text-center w-20 h-20"}  /></div></div>
          <div id='bloc4_1'  className={ "w-full text-lg p-2 px-6 whitespace-pre-line text-center"}     >{`Optez pour notre service de taxi disponible 24h/24 et 7j/7.
  Qu'il s'agisse de trajets urgents, de sorties nocturnes ou de déplacements professionnels, nous sommes toujours à votre disposition même à l’international. 
  Nos chauffeurs expérimentés vous garantissent des trajets sûrs et confortables à toute heure.
  Réservez dès maintenant et voyagez sans souci, jour et nuit.
   
  Contactez-nous pour plus d'informations.`} </div></div><div id='bloc5'  className={ "bg-primary_color text-white p-2"}     > <div id='bloc5_0'  className={ "w-full p-6 flex flex-col justify-center md:flex-row items-center"}     > <div id='bloc5_0_0'  className={ "flex flex-col md:w-1/3 centre  items-center justify-center"}     > <FontAwesomeIcon  id='bloc5_0_0_0'  icon={ fa.faAutomobile}      className={ "my-2 text-lg text-center h-20 w-20"}  /></div>
            <div id='bloc5_0_1'  className={ "font-bold my-2 px-4 grow text-center text-2xl"}     >{`CHOISISSEZ LE MEILLEUR `} </div>
            <div id='bloc5_0_2'  className={ "flex flex-col md:w-1/3 centre  items-center justify-center"}     > </div></div>
          <div id='bloc5_1'  className={ "w-full p-2 text-lg px-6 whitespace-pre-line text-center"}     >{`Saviez-vous que la loi vous permet de choisir librement votre taxi pour vos déplacements médicaux ?
  Ne vous laissez pas imposer un prestataire.
  Optez pour un service personnalisé et de qualité, adapté à vos besoins.
  Avec nos taxis conventionnés, bénéficiez d’un confort optimal et d’une prise en charge complète par la Sécurité Sociale.
  Faites valoir vos droits et choisissez votre taxi en toute liberté.
  
  Contactez-nous pour plus d'informations et pour réserver votre prochain trajet médical.`} </div></div><div id='bloc6'  className={ "p-2 bg-white flex flex-col centre  items-center justify-center"}     > <div id='bloc6_0'  className={ "w-full p-2 bg-white font-medium text-2xl flex flex-col text-center"}     >{`Réservez en ligne`} </div>
          <div id='bloc6_1'  className={ "p-2 flex justify-center"}     > <div id='bloc6_1_0'  className={ "p-2 bg-white mb-10 w-3/4"}     >{`N'hésitez pas à nous envoyer un message afin de planifier votre trajet à l'avance`} </div></div>
          <div id='bloc6_2'  className={ "p-2 bg-white flex flex-col centre max-w-full p-2 flex flex-col  items-center justify-center"}     > <InputText id={"input_bloc6_2_0"} label={"Nom"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Nom":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Nom"]) ?? ''} className={"w-full p-2 bg-white"} placeholder={"Nom"} />
            <InputPhoneNumber  id={"input_bloc6_2_1"} label={"Téléphone"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Téléphone":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Téléphone"]) ?? ''} className={"p-2 flex flex-row justify-start w-full max-w-full"} placeholder={"Téléphone"}  />
            <InputText id={"input_bloc6_2_2"} label={"Adresse Mail"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Adresse Mail":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Adresse Mail"]) ?? ''} className={"w-full p-2 bg-white"} placeholder={"Adresse Mail"} />
            <InputTextArea id={"input_bloc6_2_3"} label={"Message"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Message":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Message"]) ?? ''} className={"p-2 flex flex-row justify-start h-40 w-full max-w-full"} placeholder={"Message"}  />
            <div id='bloc6_2_4'  className={ "flex mx-52 p-3 text-gray-400 mb-2 justify-center"}     > <div id='bloc6_2_4_0'  className={ "text-white font-semibold py-2 rounded-2xl px-10 inline-block bg-secondary_color appbutton"}    onClick = { action_event_437_bloc6_2_4_0} >{`ENVOYER`} </div></div></div></div><div id='bloc7'  className={ "w-full bg-black bg-primary_color flex flex-row justify-center"}     > <div id='bloc7_0'  className={ "p-2 h grid grids-cols-1 md:grid-cols-2 gap-1 text-white lg:grid-cols-3 pb-40"}     > <div id='bloc7_0_0'  className={ " flex flex-col items-start p-4"}     > <div id='bloc7_0_0_0'  className={ "text-xl font-bold"}     >{`Gares`} </div>
              <div id='bloc7_0_0_1'  className={ "whitespace-pre-line text-left"}     >{`Gare SNCF de LYON-PART-DIEU
      Gare SNCF de LYON-PERRACHE
      Gare SNCF de LYON-VAISE
      Gare routière de LYON-perrache
      Gare SNCF de BOURG EN BRESSE
      Gare de Genève Cornavin `} </div></div>
            <div id='bloc7_0_1'  className={ " flex flex-col items-start p-4"}     > <div id='bloc7_0_1_0'  className={ "font-bold text-xl"}     >{`Hôpitaux`} </div>
              <div id='bloc7_0_1_1'  className={ "whitespace-pre-line text-left"}     >{`Hôpital Édouard herriot 
      Centre Léon Bérard 
      Hôpital de la croix rousse 
      Hôpital saint-Joseph saint Luc 
      Hôpital privé jean mermoz
      Hôpital privé natecia 
      Hôpital Louis Pradel 
      Clinique du parc
      Hôpital neurologique pierre Wertheimer
      Hôpital Lyon Sud
      Hôpital Fleyriat
      Clinique Convert `} </div>
              <div id='bloc7_0_1_2'       > </div></div>
            <div id='bloc7_0_2'  className={ " flex flex-col items-start p-4"}     > <div id='bloc7_0_2_0'  className={ "font-bold text-xl"}     >{`Aéroports `} </div>
              <div id='bloc7_0_2_1'  className={ "whitespace-pre-line text-left"}     >{`Aéroport de Lyon saint éxupery
      Aéroport international de Genève 
      Aéroport de Dole-Jura`} </div></div></div></div></>
  
}

export default Component108Page;
